import "./default.sass";
import { useCallback, useRef, useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import {
  useWalletModal,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import { useWallet } from "@solana/wallet-adapter-react";
import { clearUserInfo, getUserInfo, setUserInfo } from "../stores/userStore";
import API from "../API";
import { bs58 } from "@coral-xyz/anchor/dist/cjs/utils/bytes";

export default function DefaultLayout() {
  const { publicKey, connected, wallet, signMessage } = useWallet();
  let userInfo = getUserInfo();
  let { setVisible } = useWalletModal();
  let isLoging = useRef(false);
  let timerId = useRef<any>(null);

  useEffect(() => {
    console.log(
      "Wallet connection state or public key changed",
      connected,
      publicKey?.toBase58(),
    );

    if (connected && publicKey) {
      clearTimeout(timerId.current);

      if (isLoging.current) {
        console.log("isLoging.current", isLoging.current);
        return;
      }

      if (
        (userInfo.userToken && userInfo.expiringAt < Date.now()) ||
        !userInfo.userToken ||
        (userInfo.address &&
          userInfo?.address?.toLocaleLowerCase() !==
            publicKey?.toBase58().toLocaleLowerCase())
      ) {
        // debugger;
        console.log("login...");
        isLoging.current = true;
        (async () => {
          let nonceRes = await API.userLogin.requestNonce({
            address: publicKey.toBase58(),
            // address: "0x3BB27fC83e530cfC18769167EC1fC2238eC15b71",
          });

          API.r(nonceRes, async () => {
            let nonce = nonceRes.data.value;

            const message = new TextEncoder().encode(nonce);

            if (!signMessage)
              throw new Error("Wallet does not support message signing!");

            const signature = await signMessage(message);
            console.log(bs58.encode(signature));
            let loginRes = await API.userLogin.customerLogin({
              walletAddress: publicKey.toBase58(),
              signature: bs58.encode(signature),
            });

            API.r(loginRes, () => {
              setVisible(false);
              setUserInfo({
                ...loginRes.data,
                address: publicKey.toBase58(),
              });

              setTimeout(() => {
                // window.location.reload();
              }, 300);
            });
          });
          isLoging.current = false;
        })();
      }
    } else {
      console.log(connected, publicKey);

      /*


        delay 1 second

      */
      timerId.current = setTimeout(() => {
        clearUserInfo();
      }, 3000);
    }

    return () => {
      clearTimeout(timerId.current);
    };
  }, [connected, publicKey]);

  useEffect(() => {
    setTimeout(() => {
      if (!getUserInfo().userToken) {
        setVisible(true);
      }
    }, 3000);
  }, []);

  const location = useLocation();
  const navHighLight = (path: string) => {
    return location.pathname.startsWith(path) ? "ct-navlink-active" : "";
  };

  const modal = useRef<any>();

  return (
    <main className="ct-font-roboto pb-20">
      {/* Header -Section */}
      <div className="mb-12 grid grid-cols-[auto_1fr_auto] items-center px-10 py-5">
        <div className="grid grid-flow-col items-center gap-6">
          <Link to="/" className="grid grid-flow-col items-center gap-6">
            <img src="./logo.png" alt="" className="w-10" />
            <span className="ct-font-silk ct-text-gt1 text-2xl">
              Call Of Meme
            </span>
          </Link>
          <a href="http://x.com" target="_blank" rel="noopener noreferrer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              className="w-10"
            >
              <g clipPath="url(#clip0_365_504)">
                <path
                  d="M32 0H8C3.58172 0 0 3.58172 0 8V32C0 36.4183 3.58172 40 8 40H32C36.4183 40 40 36.4183 40 32V8C40 3.58172 36.4183 0 32 0Z"
                  fill="#191919"
                />
                <path
                  d="M32 1H8C4.13401 1 1 4.13401 1 8V32C1 35.866 4.13401 39 8 39H32C35.866 39 39 35.866 39 32V8C39 4.13401 35.866 1 32 1Z"
                  stroke="white"
                  strokeOpacity="0.03"
                  strokeWidth="2"
                />
                <path
                  d="M23.4014 28.25L12.4014 11.75H16.5986L27.5986 28.25H23.4014Z"
                  stroke="white"
                  strokeWidth="1.5"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M27 11H29L21.7179 19.1923L20.9487 17.8077L27 11ZM18.2609 20.8315L11 29H13L19.0435 22.2011L18.2609 20.8315Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_365_504">
                  <rect width="40" height="40" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>
        <div className="ct-navlink grid grid-flow-col justify-center gap-4 text-center text-lg text-white">
          {/* <Link className={isActive} to="/">
            Home
          </Link> */}
          <Link className={navHighLight("/cto")} to="/cto">
            CTO Fund
          </Link>
          <Link className={navHighLight("/tokens")} to="/tokens">
            Memes
          </Link>
          {/* <Link className={navHighLight("/h48r")} to="/h48r">
            48hours rush
          </Link> */}
          <Link className={navHighLight("/nft")} to="/nft">
            NFT
          </Link>
          <Link className={navHighLight("/my")} to="/my">
            Airdrop&Assets
          </Link>
          <span
            className="cursor-pointer"
            onClick={() => modal.current.showModal()}
          >
            How it works
          </span>
        </div>
        <div className="ct-override-wallet-btn">
          <WalletMultiButton />
          <button className="btn h-10 min-h-10 w-[9.25em] rounded-lg bg-gradient-to-r from-[#00FF80] to-[#00FFE2] text-base text-black">
            {publicKey ? publicKey.toBase58().slice(0, 6) : "Connect Wallet"}
          </button>
        </div>
      </div>

      {/* body -Section */}
      <div className="">
        <Outlet />
      </div>

      {/* fotter -Section
      <footer className="footer mt-8 hidden bg-neutral p-10 text-neutral-content">
      </footer>
      */}

      {/* How it works -Modal */}
      <dialog className="modal" ref={modal}>
        <div className="modal-box max-w-full">
          <h3 className="text-lg font-bold">Mechanism</h3>
          <p>
            Select Tokens → Initiate Proposals → Vote and Bribe → Succeed to
            Obtain CTO Funds
          </p>
          <p className="mt-2 font-bold">1. Process</p>
          <p>
            Users can use $VOTE tokens to create new proposals or cast votes on
            existing ones. Only the winning proposal will have its tokens
            repurchased, with 80% of that season’s CTO fund allocated for this
            purpose.
          </p>
          <p className="mt-2 font-bold">2. Rewards</p>
          <p>
            &nbsp;&nbsp;- The proposal creator receives a 10% reward from the
            fund.
          </p>
          <p>
            &nbsp;&nbsp;- Voters will share 10% of the fund, distributed in
            proportion to the number of votes they cast.
          </p>
          <p className="mt-2">-----</p>
          <p>Q&A</p>
          <p className="mb-2">-----</p>
          <p className="font-bold">Who can initiate a vote?</p>
          <p>
            Any holder of 10,000 $VOTE can initiate a proposal. If the proposal
            wins, all $VOTE used to create it will be refunded.
          </p>
          <p className="mt-2 font-bold">Where does the CTO fund come from?</p>
          <p>
            The initial CTO fund was raised by call.meme, totaling XXXX. Each
            subsequent round will be funded by allocating 40% of transaction
            fees from the prior round.
          </p>
          <p className="mt-2 font-bold">What is the CTO Fund threshold?</p>
          <p>
            If voting participation is below XXXX $VOTE, only 50% of the CTO
            fund’s budget will be allocated; if it exceeds this threshold, 100%
            of the budget will be utilized.
          </p>
          <p className="mt-2 font-bold">How to obtain $VOTE?</p>
          <p>
            $VOTE can be exchanged for $CALL at a rate of 1:200 (1 $VOTE = 200
            $CALL). As votes are consumed, users may need additional $CALL to
            exchange for $VOTE.
          </p>
          <p className="mt-2 font-bold">
            What happens to the $VOTE used in voting?
          </p>
          <p>
            Of the $VOTE used, 20% will be burned, and 80% will go to the
            treasury. call.meme will not utilize any treasury-held votes; they
            remain redeemable by users.
          </p>
          <p className="mt-2 font-bold">How to obtain $CALL?</p>
          <p>For further details, please refer to the provided link.</p>
          <p className="mt-2 font-bold">
            What is the duration of each voting round?
          </p>
          <p>Each voting period lasts between 2 to 4 weeks.</p>
          <div className="modal-action">
            <form method="dialog">
              <button className="btn">I'm ready to call</button>
            </form>
          </div>
        </div>
      </dialog>
    </main>
  );
}
