import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useWindowScroll } from "react-use";
import API from "../API";
import { cn, useThrottle, formatDate, formatUnit } from "../lib/utils";

export default function Tokens() {
  let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  /*
    setTokenStatus("BOUNDING")
    setTokenStatus("LAUNCHED")
  */
  let [tokenStatus, setTokenStatus] = useState(
    searchParams.get("status") || "BOUNDING",
  );

  /*
    CREATE_TIME("create_time", ""),
    LAUNCHED_TIME("launched_time", ""),
    MARKET_CAP("current_market_cap", "")
  */
  // let [tokenOrderByType, setTokenOrderByType] = useState("MARKET_CAP");

  const [data, setData] = useState([]); // 
  const [limit, setLimit] = useState(20); // 
  const [offset, setOffset] = useState(0); // 
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // 
  const [queryStr, setQueryStr] = useState("");

  /* 1 */
  const [marketCap, setMarketCap] = useState(2); //  1   2 3
  const [createTime, setCreateTime] = useState(1); //  1   2 3

  const colorMap = {
    1: ["#999999", "#999999"],
    2: ["#28E1A6", "#999999"],
    3: ["#999999", "#28E1A6"],
  };

  //
  const [scrolled, setScrolled] = useState(false); // ，
  const { y } = useWindowScroll();

  const fetchData = async (isFirstPage) => {
    console.log("fetchData....");
    setLoading(true);
    if (isFirstPage) {
      setOffset(0);
      setData([]);
    }
    let tokenOrderByType = "";
    let orderBy = "";
    let orderByMap = {
      2: "asc",
      3: "desc",
    };
    if (marketCap > 1) {
      tokenOrderByType = "current_market_cap";
      orderBy = orderByMap[marketCap];
    }

    if (createTime > 1) {
      tokenOrderByType = "create_time";
      orderBy = orderByMap[createTime];
    }

    let data = {
      keyword: queryStr.trim() || "",
      tokenStatus: tokenStatus,
      page: {
        offset: isFirstPage ? 0 : offset,
        limit,
      },
      tokenOrderByType,
      orderBy, // 
    };
    console.log(data);
    try {
      const response = await API.meme.pageTokenWithSearch(data);
      const newData = response.data.value;

      if (newData.length > 0) {
        if (isFirstPage) {
          setData((prevData) => [...newData]);
        } else {
          setData((prevData) => [...prevData, ...newData]);
        }

        setOffset((prevOffset) => prevOffset + limit); // 
      } else {
        setHasMore(false); // 
      }
    } catch (error) {
      console.error(":", error);
    } finally {
      setLoading(false);
    }
  };

  const checkScrollPosition = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    if (y > 100) {
      setScrolled(true); // 
    }
    if (scrolled && y + clientHeight >= scrollHeight - 100) {
      console.log("bottom...");
      if (!loading && hasMore) {
        fetchData();
      }
    }
  };

  //  useThrottle ，200 
  const throttledCheckScroll = useThrottle(checkScrollPosition, 200);

  useEffect(() => {
    throttledCheckScroll();
  }, [y, throttledCheckScroll]);

  useEffect(() => {
    // console.log(tokenStatus, createTime, marketCap);
    fetchData(true);
    getLatestKillOfTime();
  }, [tokenStatus, createTime, marketCap]);

  const handleKeyDown = function (event) {
    if (event.key === "Enter") {
      console.log("Enter");
      fetchData(true);
    }
  };

  //
  const [LatestKillOfTime, setLatestKillOfTime] = useState({});
  const getLatestKillOfTime = async function () {
    let res = await API.meme.getLatestKillOfTime();
    setLatestKillOfTime(res.data || {});
  };

  const baseClass =
      "h-10 cursor-pointer rounded-lg px-3 text-base leading-10 bg-white bg-opacity-10 text-white",
    hoverClass =
      "h-10 cursor-pointer rounded-lg px-3 text-base leading-10 bg-gradient-to-r from-[#00FF80] to-[#00FFE2] font-bold text-black";

  return (
    <>
      {/* King of the hill -Section */}

      <div className="mx-auto mb-4 grid max-w-[1400px] justify-center">
        {LatestKillOfTime.address ? (
          <div className="relative w-[500px] rounded-lg border-2 border-[#0be570] bg-[#191919] p-5">
            <div className="grid grid-cols-[auto_1fr_auto] gap-6">
              <div className="w-[100px]">
                <Link to="/" className="relative">
                  <img
                    src={LatestKillOfTime.image}
                    alt=""
                    className="h-[100px] w-full object-cover"
                  />
                  <img
                    src="./avatar_mask.png"
                    alt=""
                    className="absolute top-0 w-full"
                  />
                </Link>
              </div>
              <div className="pt-4">
                <div className="mb-1 grid grid-flow-col justify-start gap-2 text-xs">
                  Created by
                  <img src={LatestKillOfTime.avatar || "/logo.png"} alt="" />
                  <Link to="" className="text-[#BFDBFE]">
                    {LatestKillOfTime.address
                      ? LatestKillOfTime.address.slice(0, 6)
                      : ""}
                  </Link>
                </div>
                <p className="mb-4 text-xs text-[#28E1A6]">
                  market cap:{" "}
                  {formatUnit(Number(LatestKillOfTime.currentMarketCap))}
                </p>
                <p className="text-sm text-white">
                  {LatestKillOfTime.name} [ticker: {LatestKillOfTime.ticker}]
                </p>
              </div>
              <div className="grid items-center">
                <Link
                  to={`/tokensdetails/${LatestKillOfTime.address}`}
                  className="grid gap-1 text-center text-[#0AD7A3]"
                >
                  <i className="flex h-10 w-10 items-center justify-center rounded-full bg-[#0AD7A3]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M13.2716 12.0186C12.947 11.9315 12.451 12.4812 12.451 12.4812C12.2924 12.6397 12.1485 12.9073 12.0213 13.1914C11.8182 13.6453 11.2487 13.8137 10.8367 13.5352C9.1586 12.4011 7.53646 10.7747 6.41102 9.09579C6.14077 8.69264 6.29476 8.14144 6.72847 7.92358C7.12454 7.72464 7.49061 7.53231 7.49061 7.53231L7.48763 7.52238C7.75847 7.3991 7.91081 7.09437 7.8314 6.79795C7.8314 6.79795 5.56568 1.25043 4.91788 1.25043C2.89185 1.25043 1.24902 2.89325 1.24902 4.91928C1.24902 5.384 1.33868 5.82425 1.49767 6.23191C3.19756 12.1306 7.84071 16.7697 13.7394 18.4737C14.1471 18.6327 14.5914 18.7223 15.0561 18.7223C17.0821 18.7223 18.7556 17.1773 18.7556 15.1513C18.7556 15.1513 15.9642 13.1007 13.2716 12.0186Z"
                        fill="white"
                      />
                    </svg>
                  </i>
                  BUY
                </Link>
              </div>
            </div>
            <img
              src="./king_hill.png"
              alt=""
              className="absolute left-3 top-0 h-[32px] w-[166px] select-none"
            />
          </div>
        ) : (
          ""
        )}

        <div className="text-center">
          <button
            onClick={() => {
              navigate("/create");
            }}
            className="btn mt-10 rounded-lg bg-gradient-to-r from-[#00FF80] to-[#00FFE2] text-base text-black"
          >
            Create A New Coin
          </button>
        </div>
      </div>
      {/* bounding Curve -Section */}
      <div className="mx-auto mt-8 max-w-[1600px] rounded-xl bg-[#17181B] p-10 pb-6">
        <div className="mb-6 grid grid-cols-[auto_auto_1fr] justify-start gap-1">
          <span
            className={tokenStatus === "BOUNDING" ? hoverClass : baseClass}
            onClick={() => setTokenStatus("BOUNDING")}
          >
            bounding Curve
          </span>
          <span
            className={tokenStatus === "LAUNCHED" ? hoverClass : baseClass}
            onClick={() => setTokenStatus("LAUNCHED")}
          >
            Launched
          </span>
          <div className="grid grid-cols-[auto_1fr]">
            <div className="grid grid-flow-col gap-8 px-8">
              <div
                onClick={() => {
                  setCreateTime(1);
                  setMarketCap((prevState) =>
                    prevState === 3 ? 2 : prevState + 1,
                  );
                }}
                className={cn(
                  "grid cursor-pointer select-none grid-flow-col items-center gap-3",
                  {
                    "text-[#28E1A6]": createTime === 1,
                  },
                )}
              >
                Market Cap
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="15"
                  viewBox="0 0 9 15"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 6.20048H0.6003V5.00078H1.1997V3.80108H1.8V3.20078H2.3994V2.00018H2.9997V1.39988H3.5991V0.799581H3.8997V0.199281H4.4991V0.799581H4.1994H4.7997V1.39988H5.3991V2.00018H5.9994V3.20078H6.5988V3.80108H7.2V5.00078H7.7994V6.20048H8.3997V6.80078H7.7994V6.20048H6.5988V5.60018H5.4V5.00168L4.5 5.00078V4.40048H3.9006V5.00078H3.0006V5.59928H1.8V6.19958H0.6003V6.79988H0V6.19958V6.20048ZM1.8 5.00078H1.1997V5.59928H1.8V4.99988V5.00078ZM1.8 5.00078V3.80108H2.3994V3.20078H2.9997V2.00018H3.5991V1.40168H4.1994V1.39988H4.7997V1.40168H4.1994V3.80108H3.8997L3.9006 4.40048H2.9997V5.00078H1.8Z"
                    fill={colorMap[marketCap][0]}
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 8.79952H0.6003V9.99922H1.1997V11.1989H1.8V11.7992H2.3994V12.9998H2.9997V13.6001H3.5991V14.2004H3.8997V14.8007H4.4991V14.2004H4.1994H4.7997V13.6001H5.3991V12.9998H5.9994V11.7992H6.5988V11.1989H7.2V9.99922H7.7994V8.79952H8.3997V8.19922H7.7994V8.79952H6.5988V9.39982H5.4V9.99832L4.5 9.99922V10.5995H3.9006V9.99922H3.0006V9.40072H1.8V8.80042H0.6003V8.20012H0V8.80042V8.79952ZM1.8 9.99922H1.1997V9.40072H1.8V10.0001V9.99922ZM1.8 9.99922V11.1989H2.3994V11.7992H2.9997V12.9998H3.5991V13.5983H4.1994V13.6001H4.7997V13.5983H4.1994V11.1989H3.8997L3.9006 10.5995H2.9997V9.99922H1.8Z"
                    fill={colorMap[marketCap][1]}
                  />
                </svg>
              </div>
              <div
                onClick={() => {
                  setMarketCap(1);
                  setCreateTime((prevState) =>
                    prevState === 3 ? 2 : prevState + 1,
                  );
                }}
                className={cn(
                  "grid cursor-pointer select-none grid-flow-col items-center gap-3",
                  {
                    "text-[#28E1A6]": marketCap === 1,
                  },
                )}
              >
                Create Time
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="15"
                  viewBox="0 0 9 15"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 6.20048H0.6003V5.00078H1.1997V3.80108H1.8V3.20078H2.3994V2.00018H2.9997V1.39988H3.5991V0.799581H3.8997V0.199281H4.4991V0.799581H4.1994H4.7997V1.39988H5.3991V2.00018H5.9994V3.20078H6.5988V3.80108H7.2V5.00078H7.7994V6.20048H8.3997V6.80078H7.7994V6.20048H6.5988V5.60018H5.4V5.00168L4.5 5.00078V4.40048H3.9006V5.00078H3.0006V5.59928H1.8V6.19958H0.6003V6.79988H0V6.19958V6.20048ZM1.8 5.00078H1.1997V5.59928H1.8V4.99988V5.00078ZM1.8 5.00078V3.80108H2.3994V3.20078H2.9997V2.00018H3.5991V1.40168H4.1994V1.39988H4.7997V1.40168H4.1994V3.80108H3.8997L3.9006 4.40048H2.9997V5.00078H1.8Z"
                    fill={colorMap[createTime][0]}
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 8.79952H0.6003V9.99922H1.1997V11.1989H1.8V11.7992H2.3994V12.9998H2.9997V13.6001H3.5991V14.2004H3.8997V14.8007H4.4991V14.2004H4.1994H4.7997V13.6001H5.3991V12.9998H5.9994V11.7992H6.5988V11.1989H7.2V9.99922H7.7994V8.79952H8.3997V8.19922H7.7994V8.79952H6.5988V9.39982H5.4V9.99832L4.5 9.99922V10.5995H3.9006V9.99922H3.0006V9.40072H1.8V8.80042H0.6003V8.20012H0V8.80042V8.79952ZM1.8 9.99922H1.1997V9.40072H1.8V10.0001V9.99922ZM1.8 9.99922V11.1989H2.3994V11.7992H2.9997V12.9998H3.5991V13.5983H4.1994V13.6001H4.7997V13.5983H4.1994V11.1989H3.8997L3.9006 10.5995H2.9997V9.99922H1.8Z"
                    fill={colorMap[createTime][1]}
                  />
                </svg>
              </div>
            </div>

            <div className="grid grid-cols-[1fr_auto] gap-4">
              <label className="input input-bordered flex h-10 items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="h-4 w-4 opacity-70"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                    clipRule="evenodd"
                  />
                </svg>
                <input
                  type="text"
                  className="grow"
                  placeholder="Search..."
                  value={queryStr}
                  onChange={(e) => setQueryStr(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </label>
              <button
                onClick={() => {
                  fetchData(true);
                }}
                className="btn h-10 min-h-min rounded-lg bg-gradient-to-r from-[#00FF80] to-[#00FFE2] text-base text-black"
              >
                Search
              </button>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-6">
          {data.map((_, item) => (
            <div
              className="rounded-lg border-2 border-[rgb(255_255_255)] border-opacity-5 bg-[#191919] p-5"
              key={item}
            >
              <div className="grid grid-cols-[auto_1fr_auto] gap-6">
                <div className="w-[100px]">
                  <Link to={`/tokensdetails/${_.address}`} className="relative">
                    <img
                      src={_.image}
                      alt=""
                      className="h-[100px] w-full object-cover"
                    />
                    <img
                      src="./avatar_mask.png"
                      alt=""
                      className="absolute top-0 w-full"
                    />
                  </Link>
                </div>
                <div className="pt-4">
                  <div className="mb-1 grid grid-flow-col justify-start gap-2 text-xs">
                    Created by
                    <img
                      src={_.avatar || "./avatar_sample_2.png"}
                      alt=""
                      className="h-4 w-4"
                    />
                    <Link to="/" className="text-[#BFDBFE]">
                      {_.creatorAddress.slice(0, 6)}
                    </Link>
                  </div>
                  <p className="mb-4 text-xs text-[#28E1A6]">
                    market cap: {formatUnit(_.currentMarketCap * 1)}
                  </p>
                  <p className="text-sm text-white">
                    {_.name} [ticker: {_.ticker}]
                  </p>
                </div>
                <div className="grid items-center">
                  <Link
                    to={`/tokensdetails/${_.address}`}
                    className="grid gap-1 text-center text-[#0AD7A3]"
                  >
                    <i className="flex h-10 w-10 items-center justify-center rounded-full bg-[#0AD7A3]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M13.2716 12.0186C12.947 11.9315 12.451 12.4812 12.451 12.4812C12.2924 12.6397 12.1485 12.9073 12.0213 13.1914C11.8182 13.6453 11.2487 13.8137 10.8367 13.5352C9.1586 12.4011 7.53646 10.7747 6.41102 9.09579C6.14077 8.69264 6.29476 8.14144 6.72847 7.92358C7.12454 7.72464 7.49061 7.53231 7.49061 7.53231L7.48763 7.52238C7.75847 7.3991 7.91081 7.09437 7.8314 6.79795C7.8314 6.79795 5.56568 1.25043 4.91788 1.25043C2.89185 1.25043 1.24902 2.89325 1.24902 4.91928C1.24902 5.384 1.33868 5.82425 1.49767 6.23191C3.19756 12.1306 7.84071 16.7697 13.7394 18.4737C14.1471 18.6327 14.5914 18.7223 15.0561 18.7223C17.0821 18.7223 18.7556 17.1773 18.7556 15.1513C18.7556 15.1513 15.9642 13.1007 13.2716 12.0186Z"
                          fill="white"
                        />
                      </svg>
                    </i>
                    BUY
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
        {loading && <p className="text-center">loading...</p>}
        {/* {!hasMore && <p className="text-center">-- end --</p>} */}

        {/* {isShow === 1 ? (
          <>
            <div className="grid grid-cols-3 gap-6">
              {new Array(27).fill(0).map((_, item) => (
                <div
                  className="rounded-lg border-2 border-[rgb(255_255_255)] border-opacity-5 bg-[#191919] p-5"
                  key={item}
                >
                  <div className="grid grid-cols-[auto_1fr_auto] gap-6">
                    <div className="w-[100px]">
                      <Link to="/" className="relative">
                        <img
                          src="./avatar_sample.jpg"
                          alt=""
                          className="w-full"
                        />
                        <img
                          src="./avatar_mask.png"
                          alt=""
                          className="absolute top-0 w-full"
                        />
                      </Link>
                    </div>
                    <div className="pt-4">
                      <div className="mb-1 grid grid-flow-col justify-start gap-2 text-xs">
                        Created by
                        <img src="./avatar_sample_2.png" alt="" />
                        <Link to="/" className="text-[#BFDBFE]">
                          6YQcY6
                        </Link>
                      </div>
                      <p className="mb-4 text-xs text-[#28E1A6]">
                        market cap: 18.42K
                      </p>
                      <p className="text-sm text-white">
                        Hype Beast Chicken [ticker: HYPE]
                      </p>
                    </div>
                    <div className="grid items-center">
                      <Link
                        to="/tokensdetails/111"
                        className="grid gap-1 text-center text-[#0AD7A3]"
                      >
                        <i className="flex h-10 w-10 items-center justify-center rounded-full bg-[#0AD7A3]">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M13.2716 12.0186C12.947 11.9315 12.451 12.4812 12.451 12.4812C12.2924 12.6397 12.1485 12.9073 12.0213 13.1914C11.8182 13.6453 11.2487 13.8137 10.8367 13.5352C9.1586 12.4011 7.53646 10.7747 6.41102 9.09579C6.14077 8.69264 6.29476 8.14144 6.72847 7.92358C7.12454 7.72464 7.49061 7.53231 7.49061 7.53231L7.48763 7.52238C7.75847 7.3991 7.91081 7.09437 7.8314 6.79795C7.8314 6.79795 5.56568 1.25043 4.91788 1.25043C2.89185 1.25043 1.24902 2.89325 1.24902 4.91928C1.24902 5.384 1.33868 5.82425 1.49767 6.23191C3.19756 12.1306 7.84071 16.7697 13.7394 18.4737C14.1471 18.6327 14.5914 18.7223 15.0561 18.7223C17.0821 18.7223 18.7556 17.1773 18.7556 15.1513C18.7556 15.1513 15.9642 13.1007 13.2716 12.0186Z"
                              fill="white"
                            />
                          </svg>
                        </i>
                        BUY
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : null}
        {isShow === 2 ? (
          <>
            <div className="grid grid-cols-3 gap-6">
              {new Array(17).fill(0).map((_, item) => (
                <div
                  className="rounded-lg border-2 border-[rgb(255_255_255)] border-opacity-5 bg-[#191919] p-5"
                  key={item}
                >
                  <div className="grid grid-cols-[auto_1fr_auto] gap-6">
                    <div className="w-[100px]">
                      <Link to="/" className="relative">
                        <img
                          src="./avatar_sample.jpg"
                          alt=""
                          className="w-full"
                        />
                        <img
                          src="./avatar_mask.png"
                          alt=""
                          className="absolute top-0 w-full"
                        />
                      </Link>
                    </div>
                    <div className="pt-4">
                      <div className="mb-1 grid grid-flow-col justify-start gap-2 text-xs">
                        Created by
                        <img src="./avatar_sample_2.png" alt="" />
                        <Link to="/" className="text-[#BFDBFE]">
                          6YQcY6
                        </Link>
                      </div>
                      <p className="mb-4 text-xs text-[#28E1A6]">
                        market cap: 18.42K
                      </p>
                      <p className="text-sm text-white">
                        Hype Beast Chicken [ticker: HYPE]
                      </p>
                    </div>
                    <div className="grid items-center">
                      <Link
                        to="/tokensdetails/111"
                        className="grid gap-1 text-center text-[#0AD7A3]"
                      >
                        <i className="flex h-10 w-10 items-center justify-center rounded-full bg-[#0AD7A3]">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M13.2716 12.0186C12.947 11.9315 12.451 12.4812 12.451 12.4812C12.2924 12.6397 12.1485 12.9073 12.0213 13.1914C11.8182 13.6453 11.2487 13.8137 10.8367 13.5352C9.1586 12.4011 7.53646 10.7747 6.41102 9.09579C6.14077 8.69264 6.29476 8.14144 6.72847 7.92358C7.12454 7.72464 7.49061 7.53231 7.49061 7.53231L7.48763 7.52238C7.75847 7.3991 7.91081 7.09437 7.8314 6.79795C7.8314 6.79795 5.56568 1.25043 4.91788 1.25043C2.89185 1.25043 1.24902 2.89325 1.24902 4.91928C1.24902 5.384 1.33868 5.82425 1.49767 6.23191C3.19756 12.1306 7.84071 16.7697 13.7394 18.4737C14.1471 18.6327 14.5914 18.7223 15.0561 18.7223C17.0821 18.7223 18.7556 17.1773 18.7556 15.1513C18.7556 15.1513 15.9642 13.1007 13.2716 12.0186Z"
                              fill="white"
                            />
                          </svg>
                        </i>
                        BUY
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : null} */}
      </div>
    </>
  );
}
