import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useWindowScroll } from "react-use";
import { cn, useThrottle, formatDate, formatUnit, num2str } from "../lib/utils";
import API from "../API";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { getAssociatedTokenAddressSync } from "@solana/spl-token";
import { PublicKey } from "@solana/web3.js";
import { callTokenAddress, voteTokenAddress } from "../anchor/setup";
import { useVote } from "../hooks/useVote";
import { useCallToVote } from "../hooks/useCallToVote";
export default function Ctofundcreate() {

  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [scrolled, setScrolled] = useState(false);
  const { y } = useWindowScroll();
  const checkScrollPosition = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    if (y > 100) {
      setScrolled(true);
    }
    if (scrolled && y + clientHeight >= scrollHeight - 100) {
      console.log("bottom...");
      if (!loading && hasMore && data.length) {
        fetchData();
      }
    }
  };

  const throttledCheckScroll = useThrottle(checkScrollPosition, 200);

  useEffect(() => {
    throttledCheckScroll();
  }, [y, throttledCheckScroll]);

  //pagination--end

  // search
  const [data, setData] = useState([]);
  const [queryStr, setQueryStr] = useState("");

  const [marketCap, setMarketCap] = useState(2); //  1 default  2 up 3 done
  const [createTime, setCreateTime] = useState(1); //  1 default  2 up 3 done
  const colorMap = {
    1: ["#999999", "#999999"],
    2: ["#28E1A6", "#999999"],
    3: ["#999999", "#28E1A6"],
  };

  const fetchData = async (isFirstPage) => {
    console.log("fetchData....");
    setLoading(true);
    if (isFirstPage) {
      setOffset(0);
      setData([]);
    }
    let tokenOrderByType = "";
    let orderBy = "";
    let orderByMap = {
      2: "desc",
      3: "asc",
    };
    if (marketCap > 1) {
      tokenOrderByType = "current_market_cap";
      orderBy = orderByMap[marketCap];
    }

    if (createTime > 1) {
      tokenOrderByType = "create_time";
      orderBy = orderByMap[createTime];
    }

    let data = {
      // keyword: queryStr.trim() || "",
      page: {
        offset: isFirstPage ? 0 : offset,
        limit,
      },
      // tokenOrderByType,
      // orderBy,
    };
    console.log(data);
    try {
      const response = await API.meme.getProposalCTOFundingToken(data);
      const newData = response.data.value;

      if (newData.length > 0) {
        if (isFirstPage) {
          setData((prevData) => [...newData]);
        } else {
          setData((prevData) => [...prevData, ...newData]);
        }

        setOffset((prevOffset) => prevOffset + limit);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("load failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = function (event) {
    if (event.key === "Enter") {
      console.log("Enter");
      fetchData(true);
    }
  };

  let navigate = useNavigate();
  useEffect(() => {
    fetchData(true);
  }, [createTime, marketCap]);

  const { connection } = useConnection();
  const { publicKey } = useWallet();
  const [callTokenBalance, setCallTokenBalance] = useState(0);
  const [voteTokenBalance, setVoteTokenBalance] = useState(0);

  const getCallTokenBalance = async function () {
    if (!publicKey) {
      return;
    }
    let splToken = new PublicKey(callTokenAddress);
    const traderTokenAccount = getAssociatedTokenAddressSync(
      splToken,
      publicKey,
      true,
    );
    try {
      let t = await connection.getTokenAccountBalance(traderTokenAccount);
      console.log("getCallTokenBalance", t.value.uiAmount);
      setCallTokenBalance(t.value.uiAmount);
    } catch (error) {
      console.log(error);
    }

  };

  const getVoteTokenBalance = async function () {
    if (!publicKey) {
      return;
    }
    let splToken = new PublicKey(voteTokenAddress);
    const traderTokenAccount = getAssociatedTokenAddressSync(
      splToken,
      publicKey,
      true,
    );
    try {
      let t = await connection.getTokenAccountBalance(traderTokenAccount);
      console.log("getVoteTokenBalance", t.value.uiAmount);
      setVoteTokenBalance(t.value.uiAmount);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCallTokenBalance();
    getVoteTokenBalance();
  }, [publicKey]);

  const modal1 = useRef(null);
  const modal2 = useRef(null);
  const [tx, setTx] = useState("");
  const [delay, setdelay] = useState(1000);
  const { votehandler, isLoading } = useVote();
  const [currentToken, setCurrentToken] = useState({
    address: "",
    avatar: "",
    createdAt: "",
    creatorAddress: "",
    crowdfundingRate: 0,
    currentMarketCap: "",
    description: "",
    image: "",
    kingOfHillTime: null,
    maxHolderWhenLaunched: "",
    name: "",
    nickName: "",
    raydiumPool: "",
    telegramLink: "",
    ticker: "",
    tokenStatus: "",
    twitterLink: "",
    website: "",
  });

  // call convert vote
  const [voteCnt, setVoteCnt] = useState(0);
  const [callToConsume, setCallToConsume] = useState(0);
  const [convertCallToVote, isConverting] = useCallToVote()

  useEffect(() => {
    get48HoursRushTime();
  }, []);

  useEffect(() => {
    setCallToConsume(voteCnt * 200)
  }, [voteCnt]);

  const get48HoursRushTime = async function () {
    let res = await API.meme.get48HoursRushTraderList();
    console.log(res);
    setdelay(Math.floor((new Date(res.data.endTime) - Date.now()) / 1000));
  };

  return (
    <>
      {/* Create your proposal -Section */}
      <div className="mx-auto flex max-w-[1400px] flex-col items-center gap-4">
        <h1 className="ct-font-silk ct-text-gt4 text-[40px] text-[#FF8700]">
          Create your proposal
        </h1>
        <p className="text-center text-2xl text-[#C1C1C1]">
          You can create a voting proposal from shitcoins pool. It costs 10,000
          $call which you can earn by trading in CALL.MEME. If your proposal
          win, your cost of creation this proposal will be completely refund.
          More than that u will earn 10% of total budget
        </p>
      </div>
      <div className="mx-auto my-8 flex max-w-[1600px] items-center gap-12 text-3xl font-bold text-[#FF8700]">
        <p>
          $VOTE<span className="ml-4">{num2str(Number(voteTokenBalance))}</span>
        </p>
        <button
          className="btn h-10 min-h-min bg-[#FF8700] text-base font-normal text-black hover:bg-[#FF8700]"
          onClick={() => {
            modal2.current.showModal();
          }}
        >
          Get More $VOTE
        </button>
      </div>

      {/* Propose -Section */}
      <div className="mx-auto max-w-[1600px] rounded-xl bg-[#17181B] p-10 pb-6">
        {/* {search filter below} */}
        {/* <div className="mb-6 grid grid-cols-[auto_1fr]">
          <div className="mr-[20rem] grid grid-flow-col gap-8">
            <div onClick={() => {
                  setCreateTime(1);
                  setMarketCap((prevState) =>
                    prevState === 3 ? 2 : prevState + 1,
                  );
                }}
                className={cn(
                  "grid cursor-pointer select-none grid-flow-col items-center gap-3",
                  {
                    "text-[#28E1A6]": createTime === 1,
                  },
                )}>
              Market Cap
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="15"
                  viewBox="0 0 9 15"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 6.20048H0.6003V5.00078H1.1997V3.80108H1.8V3.20078H2.3994V2.00018H2.9997V1.39988H3.5991V0.799581H3.8997V0.199281H4.4991V0.799581H4.1994H4.7997V1.39988H5.3991V2.00018H5.9994V3.20078H6.5988V3.80108H7.2V5.00078H7.7994V6.20048H8.3997V6.80078H7.7994V6.20048H6.5988V5.60018H5.4V5.00168L4.5 5.00078V4.40048H3.9006V5.00078H3.0006V5.59928H1.8V6.19958H0.6003V6.79988H0V6.19958V6.20048ZM1.8 5.00078H1.1997V5.59928H1.8V4.99988V5.00078ZM1.8 5.00078V3.80108H2.3994V3.20078H2.9997V2.00018H3.5991V1.40168H4.1994V1.39988H4.7997V1.40168H4.1994V3.80108H3.8997L3.9006 4.40048H2.9997V5.00078H1.8Z"
                    fill={colorMap[marketCap][0]}
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 8.79952H0.6003V9.99922H1.1997V11.1989H1.8V11.7992H2.3994V12.9998H2.9997V13.6001H3.5991V14.2004H3.8997V14.8007H4.4991V14.2004H4.1994H4.7997V13.6001H5.3991V12.9998H5.9994V11.7992H6.5988V11.1989H7.2V9.99922H7.7994V8.79952H8.3997V8.19922H7.7994V8.79952H6.5988V9.39982H5.4V9.99832L4.5 9.99922V10.5995H3.9006V9.99922H3.0006V9.40072H1.8V8.80042H0.6003V8.20012H0V8.80042V8.79952ZM1.8 9.99922H1.1997V9.40072H1.8V10.0001V9.99922ZM1.8 9.99922V11.1989H2.3994V11.7992H2.9997V12.9998H3.5991V13.5983H4.1994V13.6001H4.7997V13.5983H4.1994V11.1989H3.8997L3.9006 10.5995H2.9997V9.99922H1.8Z"
                    fill={colorMap[marketCap][1]}
                  />
                </svg>
            </div>
            <div
                onClick={() => {
                  setMarketCap(1);
                  setCreateTime((prevState) =>
                    prevState === 3 ? 2 : prevState + 1,
                  );
                }}
                className={cn(
                  "grid cursor-pointer select-none grid-flow-col items-center gap-3",
                  {
                    "text-[#28E1A6]": marketCap === 1,
                  },
                )}
              >
              Create Time
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="15"
                  viewBox="0 0 9 15"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 6.20048H0.6003V5.00078H1.1997V3.80108H1.8V3.20078H2.3994V2.00018H2.9997V1.39988H3.5991V0.799581H3.8997V0.199281H4.4991V0.799581H4.1994H4.7997V1.39988H5.3991V2.00018H5.9994V3.20078H6.5988V3.80108H7.2V5.00078H7.7994V6.20048H8.3997V6.80078H7.7994V6.20048H6.5988V5.60018H5.4V5.00168L4.5 5.00078V4.40048H3.9006V5.00078H3.0006V5.59928H1.8V6.19958H0.6003V6.79988H0V6.19958V6.20048ZM1.8 5.00078H1.1997V5.59928H1.8V4.99988V5.00078ZM1.8 5.00078V3.80108H2.3994V3.20078H2.9997V2.00018H3.5991V1.40168H4.1994V1.39988H4.7997V1.40168H4.1994V3.80108H3.8997L3.9006 4.40048H2.9997V5.00078H1.8Z"
                    fill={colorMap[createTime][0]}
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 8.79952H0.6003V9.99922H1.1997V11.1989H1.8V11.7992H2.3994V12.9998H2.9997V13.6001H3.5991V14.2004H3.8997V14.8007H4.4991V14.2004H4.1994H4.7997V13.6001H5.3991V12.9998H5.9994V11.7992H6.5988V11.1989H7.2V9.99922H7.7994V8.79952H8.3997V8.19922H7.7994V8.79952H6.5988V9.39982H5.4V9.99832L4.5 9.99922V10.5995H3.9006V9.99922H3.0006V9.40072H1.8V8.80042H0.6003V8.20012H0V8.80042V8.79952ZM1.8 9.99922H1.1997V9.40072H1.8V10.0001V9.99922ZM1.8 9.99922V11.1989H2.3994V11.7992H2.9997V12.9998H3.5991V13.5983H4.1994V13.6001H4.7997V13.5983H4.1994V11.1989H3.8997L3.9006 10.5995H2.9997V9.99922H1.8Z"
                    fill={colorMap[createTime][1]}
                  />
                </svg>
            </div>
          </div>

          <div className="grid grid-cols-[1fr_auto] gap-4">
            <label className="input input-bordered flex h-10 items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="h-4 w-4 opacity-70"
              >
                <path
                  fillRule="evenodd"
                  d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                  clipRule="evenodd"
                />
              </svg>
              <input
                  type="text"
                  className="grow"
                  placeholder="Search..."
                  value={queryStr}
                  onChange={(e) => setQueryStr(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
            </label>
            <button
              onClick={() => {
                fetchData(true);
              }}
              className="btn h-10 min-h-min rounded-lg bg-gradient-to-r from-[#00FF80] to-[#00FFE2] text-base text-black"
            >
              Search
            </button>
          </div>
        </div> */}

        <div>
          <div className="grid grid-cols-3 gap-6">
            {data.map((_, item) => (
              <div
                className="rounded-lg border-2 border-[rgb(255_255_255)] border-opacity-5 bg-[#191919] p-5"
                key={item}
              >
                <div className="grid grid-cols-[auto_1fr_auto] gap-6">
                  <div className="w-[100px]">
                    <Link
                      to={`/tokensdetails/${_.address}`}
                      className="relative"
                    >
                      <img
                        src={_.image}
                        alt=""
                        className="h-[100px] w-full object-cover"
                      />
                      <img
                        src="./avatar_mask.png"
                        alt=""
                        className="absolute top-0 w-full"
                      />
                    </Link>
                  </div>
                  <div className="pt-4">
                    <div className="mb-1 grid grid-flow-col justify-start gap-2 text-xs">
                      Created by
                      <img
                        src={_.avatar || "/logo.png"}
                        alt=""
                        className="h-4 w-4"
                      />
                      <Link to="" className="text-[#BFDBFE]">
                        {_.creatorAddress.slice(0, 6)}
                      </Link>
                    </div>
                    <p className="mb-4 text-xs text-[#28E1A6]">
                      market cap: {formatUnit(_.currentMarketCap * 1)}
                    </p>
                    <p className="text-sm text-white">
                      {_.name} [ticker: {_.ticker}]
                    </p>
                  </div>
                  <div className="grid items-center">
                    <Link
                      to=""
                      className="grid gap-1 text-[#FF8700]"
                      onClick={() => {
                        setCurrentToken(_);
                        get48HoursRushTime();
                        modal1.current.showModal();
                      }}
                    >
                      <i className="mx-auto flex h-10 w-10 items-center justify-center rounded-full bg-[#FF8700]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M13.2716 12.0186C12.947 11.9315 12.451 12.4812 12.451 12.4812C12.2924 12.6397 12.1485 12.9073 12.0213 13.1914C11.8182 13.6453 11.2487 13.8137 10.8367 13.5352C9.1586 12.4011 7.53646 10.7747 6.41102 9.09579C6.14077 8.69264 6.29476 8.14144 6.72847 7.92358C7.12454 7.72464 7.49061 7.53231 7.49061 7.53231L7.48763 7.52238C7.75847 7.3991 7.91081 7.09437 7.8314 6.79795C7.8314 6.79795 5.56568 1.25043 4.91788 1.25043C2.89185 1.25043 1.24902 2.89325 1.24902 4.91928C1.24902 5.384 1.33868 5.82425 1.49767 6.23191C3.19756 12.1306 7.84071 16.7697 13.7394 18.4737C14.1471 18.6327 14.5914 18.7223 15.0561 18.7223C17.0821 18.7223 18.7556 17.1773 18.7556 15.1513C18.7556 15.1513 15.9642 13.1007 13.2716 12.0186Z"
                            fill="white"
                          />
                        </svg>
                      </i>
                      Propose
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Propose -Modal */}
      <dialog ref={modal1} className="modal">
        <div className="modal-box border border-white">
          <form method="dialog">
            <button className="btn btn-circle btn-ghost btn-sm absolute right-2 top-2">
              ✕
            </button>
          </form>
          <div className="grid justify-center gap-2 text-center">
            <div className="w-80 text-2xl font-bold text-white">
              You will create Proposal for token{" "}
              <span className="text-[#FF8700]">{currentToken.name}</span>
            </div>
            <div className="text-2xl font-bold text-[#FF8700]">
              It will cost you 10,000 $VOTE
            </div>
            <h3 className="text-base text-[#C1C1C1]">
              You have {num2str(Number(callTokenBalance))} $VOTE
            </h3>
            <h3 className="text-base text-[#C1C1C1]">Confirming transaction</h3>
            <div className="mt-4 flex gap-4">
              {tx ? (
                <a
                  target="_blank"
                  href={`https://explorer.solana.com/tx/${tx}`}
                  className="btn h-11 min-h-11 w-[120px] border border-[#FF8700] text-base font-normal text-[#FF8700] hover:border-[#FF8700]"
                >
                  view tx
                </a>
              ) : (
                ""
              )}

              <button
                onClick={() => {
                  votehandler(
                    currentToken.address,
                    publicKey.toBase58(),
                    delay,
                  );
                }}
                className="btn h-11 min-h-11 flex-grow bg-[#FF8700] text-base font-normal text-black hover:bg-[#FF8700]"
              >
                Confirm
                {isLoading ? (
                  <span className="loading loading-infinity loading-lg text-[#eaeaea]"></span>
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        </div>
      </dialog>

      {/* $CALL to $VOTE -Modal */}
      <dialog ref={modal2} className="modal">
        <div className="modal-box border border-white">
          <form method="dialog">
            <button className="btn btn-circle btn-ghost btn-sm absolute right-2 top-2">
              ✕
            </button>
          </form>
          <div className="grid justify-center gap-4 text-center">
            <div className="w-80 text-2xl font-bold text-[#FF8700]">
              200 $CALL <span className="mx-4 text-white">to</span> 1 $VOTE
            </div>
            <h3 className="text-base text-[#C1C1C1]">
              You have{" "}
              <span className="text-[#FF8700]">
                {num2str(Number(callTokenBalance))}
              </span>{" "}
              $CALL
            </h3>
            <div className="flex items-center justify-center gap-4">
              BUY
              <input
                type="number"
                pattern="\d*"
                className="ct-num-input input input-bordered h-10 w-20 bg-gray-800 pl-0 text-center text-white"
                onChange={(e) => {
                  setVoteCnt(e.target.value)
                }}
              />
              <span className="text-[#FF8700]">$VOTE</span>
              (Cost {callToConsume} $Call)
            </div>
            <div className="mt-4 flex gap-4">
              <button className="btn h-11 min-h-11 flex-grow bg-[#FF8700] text-base font-normal text-black hover:bg-[#FF8700]" onClick={() => {
                convertCallToVote({amount: voteCnt})
              }}>
                BUY
                {isConverting && (
                  <span className="loading loading-infinity loading-lg text-[#eaeaea]"></span>
                )}
              </button>
            </div>
          </div>
        </div>
      </dialog>
    </>
  );
}
