import { useEffect, useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import API from "../API";
import { getUserInfo, setUserInfo } from "../stores/userStore";
import { useWallet } from "@solana/wallet-adapter-react";
import 'swiper/css';

export default function Airdrop() {
  const { publicKey } = useWallet();

  const [userAirdropSignInfo, setUserAirdropSignInfo] = useState({
    isRegisterAirDrop: false,
    airDropSignInfoRespList: []
  });

  const [txid, setTxid] = useState("");

  
  const applyAirdrop = useCallback(async function () {
    let res = await API.meme.applyAirDrop({pumpTxId: txid});
    API.r(res, () => {
      if (res.data.value) {
        toast.success("Submit Success", {
          autoClose: 3000,
        });
      } else {
        toast.error("Submit Failed", {
          autoClose: 3000,
        });
      }
      
    });
  }, [txid]);


  useEffect(() => {

  }, []);

  return (
    <>
      {/* -Section */}
      <div className={`mx-auto mb-32 flex max-w-[1600px] gap-5`}>
        <div className="h-[510px] mx-auto mt-20 w-full max-w-[1600px] rounded-xl border border-[#0AD7A3] bg-[#17181B] p-8 overflow-hidden">
          <div className={`flex justify-center ${userAirdropSignInfo.isRegisterAirDrop ? "border-b" : ""} border-white border-opacity-20`}>
            <h3 className="ct-font-silk ct-text-gt1 text-[29px]">You will get 600,000 $CALL if you are eligible.</h3>
          </div>


        <div className="mx-auto w-[60%] mt-[64px]">
          <h4 className="ct-font-silk ct-text-gt1 text-xl mb-4">
              Input your TX ID to activate airdrop *
            </h4>
            <input
              id="name"
              value={txid}
              onChange={(e) => setTxid(e.target.value)}
              className="rounded-md border border-[#666] bg-[#17181B] p-2 w-full"
            />
            <p className="mt-4 text-[14px]">
              Mint NFT to get buff: <br/>
              Silver NFT +50%,Gold NFT +100% - <a href="/nft" className="ct-font-silk ct-text-gt1">Go to Mint</a> <br/>
              Congress Member +900% - <a href="/rarenft" className="ct-font-silk ct-text-gt5">Go to Mint</a><br/>
              Please note that the buff will take effect only when the airdrop is initialized. You wont have it if you get NFT after Nov. 30
            </p>
            <div className="flex h-[72px] items-center justify-center pb-4 mt-[64px]">
              <button className="btn h-10 min-h-min w-[120px] rounded-lg bg-gradient-to-r from-[#00FF80] to-[#00FFE2] text-base text-black" onClick={() => {
                applyAirdrop()
              }}>
                Submit
              </button>
            </div>
        </div>



        </div>

      </div>

    </>
  );
}
