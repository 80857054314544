//axios
import axios from "axios";
import qs from "qs";
import { toast } from "react-toastify";
import config from "./config";

import useUserStore, {
  getUserInfo,
  setUserInfo,
  clearUserInfo,
} from "./stores/userStore";
axios.defaults.baseURL = config.baseURL;

// default post application/json 

axios.defaults.headers.common["Content-Type"] =
  "application/x-www-form-urlencoded";
axios.defaults.withCredentials = true;
axios.defaults.responseType = "json";

let needLoadingCount = 0;

axios.interceptors.request.use((request) => {
  //   if (needLoadingCount > 0) NProgress.start();

  // console.log("get");
  // console.log(getUserInfo());
  // console.log("setting");
  // useStore.setState({ userInfo: { a: 2, b: 3 } });
  // console.log(getUserInfo());
  // debugger;
  // console.log(useStore.getState().clear);
  // useStore.getState().clear();
  // console.log("clear");
  // console.log(getUserInfo());
  //token

  // console.log(qs.stringify(request.data));
  if (request.method === "post") request.data = qs.stringify(request.data);
  return request;
});

// axios interceptors
axios.interceptors.response.use(
  (response) => {
    // if (needLoadingCount > 0) NProgress.done();

    var code = response.data.stat.code;
    // let newUserToken = response.data.stat.newUserToken;
    // const userStore = useUserStore();

    // reset token
    // if (newUserToken) {
    //   userStore.setToken(newUserToken, response.data.stat.newUserTokenExpire);
    // }
    switch (code) {
      case 0:
        return formatResponseData(response.data);
      case -300:
      case -180:
      case -160:
      case -360:
      case -361:
      case -181:
      case -182:
        toast.error("Please log in again");
        setTimeout(() => {
          // userStore.loginOut();
          clearUserInfo();
          window.location.href = "/";
        }, 1000);
        break;
      case -400:
        // alert({
        //   type: "warning",
        //   message: "Insufficient permissions",
        // });
        toast.error("Insufficient permissions");
        break;
      default:
        console.log(code);
        // toast.error(`${code}`);
        break;
    }
    return response.data;
  },
  (err) => {
    // if (needLoadingCount > 0) NProgress.done();
    // if (err.response.status == 504 || err.response.status == 404) {
    //   alert({ message: `${err.response.status} ` });
    // } else if (err.response.status == 403) {
    //   alert({
    //     message: `${err.response.status}`,
    //   });
    // } else {
    //   alert({ message: `${err.response.status} ` });
    // }
    toast.error(err.response.status);
    // console.log(err.response.status);
    return Promise.reject(err);
  },
);

export default axios;

export function get(url, data) {
  let getParams = {
    params: {
      ts: new Date().getTime(),
    },
  };
  data = data || {};

  Object.assign(getParams.params, data);
  return axios.get(url, getParams);
}

// post
export function post(url, data) {
  data = data || {};
  return axios.post(url, data);
}
// put
export function put(url, data) {
  return axios.put(url, data);
}
// delete
export function del(url, data) {
  return axios.delete(url, data);
}
// upload
export function uploader(url, file) {
  let params = new FormData();
  params.append("file", file);
  return axios.post(url, params);
}

let requestQueue = [];

/*
  single avoid double request
  usage：
  export function getMyAccountInfo() {
  return ajaxGateway({
    mt: "user.getMyAccountInfo",
    single:true
  });
}
*/
export function ajaxGateway(params) {
  const data = formatRequestParams(params);
  const flag = JSON.stringify(params);
  if (params.single) {
    if (requestQueue.includes(flag)) {
      console.warn(`Duplicate request blocked ${flag}`);
      return false;
    }
    requestQueue.push(flag);
  }
  if (!params.hideLoading) needLoadingCount++;
  return axios({
    method: "post",
    url: config.apiUrl,
    data: data,
  })
    .then((result) => {
      removeQueue(flag);
      if (!params.hideLoading) needLoadingCount--;
      return result;
    })
    .catch((error) => {
      removeQueue(flag);
      if (!params.hideLoading) needLoadingCount--;
      return error;
    });
}

function removeQueue(flag) {
  const index = requestQueue.indexOf(flag);
  if (index > -1) {
    requestQueue.splice(index, 1);
  }
}

function stringifyNestedObjects(obj) {
  const result = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      result[key] = typeof obj[key] === 'object' ? JSON.stringify(obj[key]) : obj[key];
    }
  }

  return result;
}

function formatRequestParams(params) {
  const { mt, data = {} } = params;
  const _aid = config.appId;
  const _ts = Number(new Date());
  const isArray = Array.isArray(mt);
  const _mt = isArray ? mt.join(",") : mt;
  let _tk = "";

  if (new Date() < getUserInfo().expiringAt) {
    _tk = getUserInfo().userToken;
  }

  const checkData = isArray ? data : [data];
  checkData.forEach((item) => {
    for (let key in item) {
      if (item[key] === undefined) {
        delete item[key];
      }
    }
  });

  let result = { _aid, _mt, _ts, _tk };
  const formatReqData = {};
  if (isArray) {
    checkData.forEach((item, i) => {
      item = stringifyNestedObjects(item)
      for (let key in item) {
        if (item.hasOwnProperty(key)) {
          formatReqData[i + "_" + key] = item[key];
        }
      }
    });
    result = Object.assign({}, result, formatReqData);
  } else {
    result = Object.assign({}, result, stringifyNestedObjects(data));
  }

  return result;
}

/**
 * @param { Object } data normal data
 * @return { Object } result format data
 */
export const formatResponseData = (data) => {
  const result = [];
  const { content, stat } = data;
  const stateArr = stat.stateList;

  stateArr.forEach((item, i) => {
    result[i] = {
      state: item,
      data: content[i],
    };
  });
  return result.length == 1 ? result[0] : result;
};
