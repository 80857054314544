import {
  PublicKey
} from "@solana/web3.js";
let env = process.env.NODE_ENV;

// exchange pump ProgramId
export const programId = new PublicKey(
  env === "development" ?
  "ERtymHEiAuD5tHzrAvaYriWHYfTu2hkyN9fR31X29PUM" :
  "ERtymHEiAuD5tHzrAvaYriWHYfTu2hkyN9fR31X29PUM",
);

// vote ProgramId
export const voteProgramId = new PublicKey(
  env === "development" ?
  "GDuf65ffYqMfx1g9vZRxMF9H25fVGYEhe1ZrNLybSfJi" :
  "2h9vWgnVggXTBWayqHQGHdTowhrLmjcTAZKKuUaoUuD8",
);

// admin address
export const tokenIssuer = new PublicKey(
  env === "development" ?
  "6aS3Fa29hh5rWMgv7z6M6geK4ub5U25eeSZLkbqUbfgD" :
  "hdJNuxkEv8Lns2Sz5ZqmkCU1pwYrb5LZjPL2AHDCcys",
);

// vote admin address
export const voteAdminAddress = new PublicKey(
  env === "development" ?
  "ArLdV2CWR44VRgp9TFJaAzeAW7qLeGJTR6V3uiQuGGMm" :
  "hdJNuxkEv8Lns2Sz5ZqmkCU1pwYrb5LZjPL2AHDCcys",
);

// $Call
export const callTokenAddress = new PublicKey(
  env === "development" ?
  "9jqfrHSdhJLiheVdF5wxkTpgg7vNWfbUgCaLmATUNoYa" :
  "9jqfrHSdhJLiheVdF5wxkTpgg7vNWfbUgCaLmATUNoYa",
);

// $Vote
export const voteTokenAddress = new PublicKey(
  env === "development" ?
  "8qMNtqtQyDnbSwZUTniLd2zVqWBBXoDedANDGRSZiq7b" :
  "8qMNtqtQyDnbSwZUTniLd2zVqWBBXoDedANDGRSZiq7b",
);

export const merkleDistributorProgramId = new PublicKey(
  env === "development" ?
  "HVdsBzUkcdBjkT5KLEekPZxdYmJ6goiMc2TYXWk8cubK" :
  "HVdsBzUkcdBjkT5KLEekPZxdYmJ6goiMc2TYXWk8cubK",
);

export const merkleDistributorAddress = new PublicKey(
  env === "development" ?
  "23WP6q5AZrzVdfABVe7WgzR24oge7cVa3maZH6SZmXwM" :
  "23WP6q5AZrzVdfABVe7WgzR24oge7cVa3maZH6SZmXwM",
);

export const RPC =
  env === "development" ?
  "https://devnet.helius-rpc.com/?api-key=d4b066f7-15d5-45ad-ac46-cde3eb26028a" :
  "https://devnet.helius-rpc.com/?api-key=d4b066f7-15d5-45ad-ac46-cde3eb26028a";
  // "https://mainnet.helius-rpc.com/?api-key=d4b066f7-15d5-45ad-ac46-cde3eb26028a";

export const vault = new PublicKey(
  "E4fYPKqnV3TpgQ1oULkeDKMmj3Ch6ULc1fg99jRkUTNX",
);
export const dev = new PublicKey(
  "45Y3bVwDQy9HN193nJb4xFb2Mv7c8v6HMTjopvfyxY2e",
);

// Raydium
export const cpSwapProgram = new PublicKey(
  "CPMDWBwJDtYax9qW7AyRuVC19Cc4L4Vcy4n2BHAbHkCW",
);

// for calc pool_id, compose chart address
export const launchConfigAddress = new PublicKey(
  "9zSzfkYy6awexsHvmggeH36pfVUdDGyCcwmjT3AQPBj6",
);

export const DEVNET_PROGRAM_ID = {
  SERUM_MARKET: new PublicKey("11111111111111111111111111111111"),
  OPENBOOK_MARKET: new PublicKey(
    "EoTcMgcDRTJVZDMZWBoU6rhYHZfkNTVEAfz3uUJRcYGj",
  ),
  UTIL1216: new PublicKey("11111111111111111111111111111111"),
  FarmV3: new PublicKey("85BFyr98MbCUU9MVTEgzx1nbhWACbJqLzho6zd6DZcWL"),
  FarmV5: new PublicKey("EcLzTrNg9V7qhcdyXDe2qjtPkiGzDM2UbdRaeaadU5r2"),
  FarmV6: new PublicKey("Farm2hJLcqPtPg8M4rR6DMrsRNc5TPm5Cs4bVQrMe2T7"),
  AmmV4: new PublicKey("HWy1jotHpo6UqeQxx49dpYYdQB8wj9Qk9MdxwjLvDHB8"),
  AmmStable: new PublicKey("DDg4VmQaJV9ogWce7LpcjBA9bv22wRp5uaTPa5pGjijF"),
  CLMM: new PublicKey("devi51mZmdwUJGU9hjN27vEz64Gps7uUefqxg27EAtH"),
  Router: new PublicKey("BVChZ3XFEwTMUk1o9i3HAf91H6mFxSwa5X2wFAWhYPhU"),
  CREATE_CPMM_POOL_PROGRAM: new PublicKey(
    "CPMDWBwJDtYax9qW7AyRuVC19Cc4L4Vcy4n2BHAbHkCW",
  ),
  CREATE_CPMM_POOL_AUTH: new PublicKey(
    "7rQ1QFNosMkUCuh7Z7fPbTHvh73b68sQYdirycEzJVuw",
  ),
  CREATE_CPMM_POOL_FEE_ACC: new PublicKey(
    "G11FKBRaAkHAKuLCgLM6K6NUc9rTjPAznRCjZifrTQe2",
  ),
  FEE_DESTINATION_ID: new PublicKey(
    "3XMrhbv989VxAMi3DErLV9eJht1pHppW5LbKxe9fkEFR",
  ),
};

// NFT

export const CONGRESS_CANDY_MACHINE_ID_STR = 
  env === "development" ?
  "H2Q1SZPtrcEnkYPpmxonscGspD3Jf8XvF6HRgMzqhUFP" :
  "H2Q1SZPtrcEnkYPpmxonscGspD3Jf8XvF6HRgMzqhUFP";

export const GOLD_CANDY_MACHINE_ID_STR = 
  env === "development" ?
  "54GzEVeWXUQG2jfWooK3roZqNf8r6HtD542rw439E4hQ" :
  "54GzEVeWXUQG2jfWooK3roZqNf8r6HtD542rw439E4hQ";

export const SILVER_CANDY_MACHINE_ID_STR = 
  env === "development" ?
  // "LmkCgZUj7ijDnDEMYSnWhEmYW8YkrMNDd8L9zV4ntf2" : 
  "ECsVAyH8s5xeCH7zkpk5G8KAKRQRNriM28NPWuBNhcDp" :
  "ECsVAyH8s5xeCH7zkpk5G8KAKRQRNriM28NPWuBNhcDp";

export const treasuryStr =
  env === "development" ?
  // "ArLdV2CWR44VRgp9TFJaAzeAW7qLeGJTR6V3uiQuGGMm" :
  "GTneE6G6kguaM81zYRok9P8v7pDxDNn7CNpPNy3YeFXE" :
  "GTneE6G6kguaM81zYRok9P8v7pDxDNn7CNpPNy3YeFXE";
