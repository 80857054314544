import * as anchor from "@coral-xyz/anchor";
import { Program, Idl, AnchorProvider } from "@coral-xyz/anchor";
import { BN } from "bn.js";
import IDL from "../anchor/voting.json";
import {
  useConnection,
  useWallet,
  useAnchorWallet,
} from "@solana/wallet-adapter-react";
import {
  Connection,
  Keypair,
  PublicKey,
  SystemProgram,
  LAMPORTS_PER_SOL,
  type Signer,
} from "@solana/web3.js";
import NodeWallet from "@coral-xyz/anchor/dist/cjs/nodewallet";
import {
  createMint,
  getMint,
  createAssociatedTokenAccount,
  getAssociatedTokenAddressSync,
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} from "@solana/spl-token";

import {
  voteAdminAddress,
  voteProgramId,
  callTokenAddress,
  RPC,
} from "../anchor/setup";
import { toast } from "react-toastify";
import { useState } from "react";

// const newToken = new PublicKey("GWKknge9vqP5CnU6Y16ri5Nwg6c5GJprdCQSF8xVsfw2");

export const useVote = function () {
  const { connection } = useConnection();
  const { publicKey } = useWallet();
  const wallet = useAnchorWallet();
  const [isLoading, setIsLoading] = useState(false);

  async function getProvider(rpc: string, wallet: any) {
    const provider = new AnchorProvider(connection, wallet, {
      skipPreflight: true,
    });
    return provider;
  }

  const votehandler = async function (
    newTokenParam: PublicKey | String,
    initiateVotingParam: PublicKey | String,
    delay: number,
  ) {
    if (!publicKey) {
      return;
    }

    debugger;
    let newToken: PublicKey;

    if (typeof newTokenParam === "string") {
      newToken = new PublicKey(newTokenParam);
    } else {
      newToken = newTokenParam as PublicKey;
    }

    let initiateVoting: PublicKey;
    if (typeof initiateVotingParam === "string") {
      initiateVoting = new PublicKey(initiateVotingParam);
    } else {
      initiateVoting = initiateVotingParam as PublicKey;
    }

    const provider = await getProvider(RPC, wallet as NodeWallet);
    const connection = provider.connection;
    anchor.setProvider(provider);

    const program = new anchor.Program(IDL as Idl, voteProgramId, provider);
    const adminPlatformTokenAccount = getAssociatedTokenAddressSync(
      callTokenAddress,
      voteAdminAddress,
      true,
    );

    const globalSettingsKey = PublicKey.findProgramAddressSync(
      [Buffer.from("global_settings")],
      voteProgramId,
    )[0];

    console.log("votehandler");
    const voteKey = PublicKey.findProgramAddressSync(
      [newToken.toBuffer(), Buffer.from("create_vote")],
      voteProgramId,
    )[0];

    const sponsorPlatformTokenAccount = getAssociatedTokenAddressSync(
      callTokenAddress,
      initiateVoting,
      true,
    );

    let now = new Date().getTime() / 1000;

    let vote;
    try {
      vote = await program.account.vote.fetch(voteKey);
    } catch (error) {
      console.log(error);
    }
    let tx;
    try {
      setIsLoading(true);
      if (!vote) {
        let transaction = await program.methods
          .createVote(newToken, new BN(now), new BN(now + delay))
          .accounts({
            payer: publicKey,
            vote: voteKey,
            sponsorTokenAccount: sponsorPlatformTokenAccount,
            adminTokenAccount: adminPlatformTokenAccount,
            globalSettings: globalSettingsKey,
            splTokenMint: newToken,
            systemProgram: SystemProgram.programId,
          })
          .transaction();

        tx = await provider.sendAndConfirm(transaction);
        toast.success("Create Vote Success!");
        console.log("create vote tx: ", tx);
        return tx;
      } else {
        toast.info("the vote has created");
        return;
        // const votePlatformTokenAccount = getAssociatedTokenAddressSync(
        //   callTokenAddress,
        //   voteKey,
        //   true,
        // );
        // let transaction = await program.methods
        //   .doVote(new BN(100 * 10 ** 9))
        //   .accounts({
        //     payer: publicKey,
        //     vote: voteKey as any,
        //     userTokenAccount: sponsorPlatformTokenAccount,
        //     adminTokenAccount: votePlatformTokenAccount,
        //     globalSettings: globalSettingsKey,
        //     splTokenMint: callTokenAddress,
        //     systemProgram: SystemProgram.programId,
        //   })
        //   .transaction();

        // tx = await provider.sendAndConfirm(transaction);
        // toast.success("Vote Success!");
        // console.log("do vote tx: ", tx);
        // return tx;
      }
    } catch (error) {
      toast.error((error as Error).toString());
    } finally {
      setIsLoading(false);
    }
  };

  const doVote = async function (
    newTokenParam: PublicKey | String,
    initiateVotingParam: PublicKey | String,
    voteNumber: number,
  ) {
    if (!publicKey) {
      return;
    }
    let newToken: PublicKey;

    if (typeof newTokenParam === "string") {
      newToken = new PublicKey(newTokenParam);
    } else {
      newToken = newTokenParam as PublicKey;
    }

    let initiateVoting: PublicKey;
    if (typeof initiateVotingParam === "string") {
      initiateVoting = new PublicKey(initiateVotingParam);
    } else {
      initiateVoting = initiateVotingParam as PublicKey;
    }

    const provider = await getProvider(RPC, wallet as NodeWallet);

    anchor.setProvider(provider);

    const program = new anchor.Program(IDL as Idl, voteProgramId, provider);

    const globalSettingsKey = PublicKey.findProgramAddressSync(
      [Buffer.from("global_settings")],
      voteProgramId,
    )[0];

    console.log("votehandler");
    const voteKey = PublicKey.findProgramAddressSync(
      [newToken.toBuffer(), Buffer.from("create_vote")],
      voteProgramId,
    )[0];

    const sponsorPlatformTokenAccount = getAssociatedTokenAddressSync(
      callTokenAddress,
      initiateVoting,
      true,
    );

    debugger;
    let tx;
    try {
      setIsLoading(true);
      const votePlatformTokenAccount = getAssociatedTokenAddressSync(
        callTokenAddress,
        voteKey,
        true,
      );
      let transaction = await program.methods
        .doVote(new BN(voteNumber * 10 ** 9))
        .accounts({
          payer: publicKey,
          vote: voteKey as any,
          userTokenAccount: sponsorPlatformTokenAccount,
          adminTokenAccount: votePlatformTokenAccount,
          globalSettings: globalSettingsKey,
          splTokenMint: callTokenAddress,
          systemProgram: SystemProgram.programId,
        })
        .transaction();

      tx = await provider.sendAndConfirm(transaction);
      toast.success("Vote Success!");
      console.log("do vote tx: ", tx);
      return tx;
    } catch (error) {
      toast.error((error as Error).toString());
    } finally {
      setIsLoading(false);
    }
  };
  return { votehandler, isLoading, doVote };
};
