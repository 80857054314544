import React, { useState, useEffect, useCallback, ReactNode } from "react";

interface Time {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

interface CountdownProps {
  endTime: number;
  children: (props: { expired: boolean; formatTime: Time }) => ReactNode;
}

const Countdown: React.FC<CountdownProps> = ({ endTime, children }) => {
  const [remainingTime, setRemainingTime] = useState<number>(
    endTime - Date.now(),
  );

  const formatTime = useCallback((time: number) => {
    let days = 0,
      hours = 0,
      minutes = 0,
      seconds = 0;
    if (time <= 0 || isNaN(time)) {
      return { days, hours, minutes, seconds };
    }
    days = Math.floor(time / 86400000);
    hours = Math.floor(time / (1000 * 60 * 60));
    minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    seconds = Math.floor((time % (1000 * 60)) / 1000);
    return { days, hours, minutes, seconds };
  }, []);

  useEffect(() => {
    const id = window.setInterval(updateCountdown, 1000);

    function updateCountdown() {
      // console.log("updateCountdown", endTime);
      let diff = endTime - Date.now();
      if (diff < 0 || isNaN(diff)) {
        window.clearInterval(id);
      }
      // console.log(diff);
      setRemainingTime(diff);
    }

    return () => {
      // console.log("clear");
      window.clearInterval(id);
    };
  }, [endTime]);

  return (
    <div>
      {children({
        expired: remainingTime <= 0,
        formatTime: formatTime(remainingTime),
      })}
    </div>
  );
};

export default Countdown;
